import React from "react"

import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { InView } from "react-intersection-observer"
import styled, { css } from "styled-components"

import { Stack } from "@kiwicom/orbit-components"
import {
  ChevronForward,
  Passengers,
  Radar,
  SeatExtraLegroom,
  SportEquipment,
  Ticket,
  Wifi,
} from "@kiwicom/orbit-components/icons"
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery"

import Container from "components/container"
import CTALink from "components/cta-link"
import Layout from "components/layout"
import RouteMapSmall from "components/route-map-small"
import { SearchWidget } from "components/search-bar"
import ServiceUpdateSummary from "components/service-update/service-update-summary"

const Section = styled.div`
  border-bottom: ${(props) =>
    "1px solid " + props.theme.orbit.backgroundSeparator};
  :last-child {
    border-bottom: none;
  }
  > div {
    display: grid;
    align-items: center;
    gap: 30px 40px;
  }
  ${media.desktop(
    css`
      > div {
        gap: 40px 80px;
      }
    `
  )}
`

const HeroContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 40px;
  gap: 20px;
  h1 {
    font-size: 30px;
    line-height: 1.2;
    margin-top: 0px;
    margin-bottom: 0.5em;
    max-width: 700px;
  }
  span.highlight {
    color: ${(props) => props.theme.orbit.paletteRedNormal};
  }
  p {
    max-width: 600px;
    margin-bottom: 0px;
  }
  ${media.mediumMobile(css`
    padding-top: 30px;
    padding-bottom: 60px;
    h1 {
      font-size: 36px;
    }
  `)}
  ${media.tablet(css`
    padding-top: 60px;
    padding-bottom: 80px;
    h1 {
      font-size: 50px;
    }
  `)}
  ${media.desktop(css`
    padding-top: 100px;
    padding-bottom: 100px;
    p {
      margin-bottom: 20px;
    }
  `)}
`

const SectionContainer = styled(Container)`
  h2 {
    border-bottom: none;
  }
  padding-top: 60px;
  padding-bottom: 60px;
  .main-text {
    max-width: 600px;
    *:last-child {
      margin-bottom: 0px;
    }
  }
  ${media.tablet(
    css`
      padding-top: 80px;
      padding-bottom: 80px;
    `
  )}
  ${media.desktop(
    css`
      padding-top: 100px;
      padding-bottom: 100px;
    `
  )}
`

const SectionHeading = styled.h2`
  margin-top: 0px;
`

const OneColumnContainer = styled(SectionContainer)`
  ${media.tablet(css`
    .main-text {
      text-align: center;
      margin: 0 auto;
    }
    a {
      margin: 0 auto;
    }
  `)}
`

const TwoColumnContainer = styled(SectionContainer)`
  grid-template-columns: 1fr;
  .gatsby-image-wrapper:last-child,
  img:last-child {
    border-radius: ${(props) => props.theme.orbit.borderRadiusLarge};
  }
  ${media.tablet(css`
    grid-template-columns: 1fr 1.5fr;
  `)}
  ${media.desktop(css`
    grid-template-columns: 1fr 1.3fr;
  `)}
`

const ExpandedImage = styled.div`
  position: relative;
  right: 0;
`

const RouteMapSectionContainer = styled(SectionContainer)`
  grid-template-columns: 1fr;
  ${media.tablet(css`
    grid-template-columns: 1fr 2fr;
  `)}
`

const FeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  ${media.tablet(css`
    margin: 0 auto;
    grid-template-columns: repeat(2, 320px);
    gap: 40px;
  `)}
  ${media.desktop(css`
    grid-template-columns: repeat(3, minmax(250px, 320px));
    gap: 50px;
  `)}
`

const Feature = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  gap: 20px;
  ${media.tablet(css`
    svg {
      width: 30px;
      height: auto;
      color: ${(props) => props.theme.orbit.paletteProductNormal};
    }
    span {
      display: inline-block;
    }
  `)}
`

const FeatureDescription = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 6px;
  align-content: baseline;
  h3,
  p {
    margin: 0;
    font-weight: 500;
    font-size: 15px;
  }
  p {
    color: ${(props) => props.theme.orbit.colorTextSecondary};
  }
`

const AnnouncementBanner = styled.a`
  cursor: pointer;
  display: table;
  margin-bottom: -10px;
  border-radius: ${(props) => props.theme.orbit.borderRadiusLarge};
  text-decoration: none;
  color: ${(props) => props.theme.orbit.paletteInkNormal};
  :hover {
    background: ${(props) => props.theme.orbit.paletteCloudLight};
    text-decoration: none;
  }
`

const AccouncementTag = styled.div`
  display: table-cell;
  vertical-align: middle;
  background: ${(props) => props.theme.orbit.paletteInkNormal};
  border-radius: ${(props) => props.theme.orbit.borderRadiusLarge} 0px 0px
    ${(props) => props.theme.orbit.borderRadiusLarge};
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  font-size: 13px;
  padding: 4px 12px;
`

const AccouncementText = styled.div`
  display: table-cell;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.orbit.paletteCloudNormal};
  border-radius: 0px ${(props) => props.theme.orbit.borderRadiusLarge}
    ${(props) => props.theme.orbit.borderRadiusLarge} 0px;
  line-height: 1.4;
  padding: 7px 14px 9px;
`

const Homepage = ({ data }) => {
  return (
    <Layout
      title="Electric bus between Dundee, Edinburgh, Glasgow, Stirling, Kinross, Edinburgh&nbsp;Airport and more"
      noFooterGap={true}
    >
      <Section>
        <HeroContainer>
          <div className="headline">
            <h1>
              Fast, comfortable travel by
              <span className="highlight"> electric&nbsp;bus</span>
            </h1>
            <Stack
              spacing="large"
              direction="column"
              spaceAfter="small"
              tablet={{ spaceAfter: "largest" }}
            >
              <p>
                Frequent services between Dundee, Glasgow, Edinburgh, Stirling,
                Kinross, Edinburgh&nbsp;Airport and more. Low fares, live
                tracking and zero emissions.
              </p>
              <AnnouncementBanner href="/news/#new-aberdeen-to-edinburgh-service-and-other-improvements-22-Sep-2024">
                <AccouncementTag>New</AccouncementTag>
                <AccouncementText>
                  <Stack
                    direction="row"
                    spacing="XXSmall"
                    align="center"
                    shrink
                    inline
                  >
                    <span>
                      Aberdeen to Edinburgh service and other stop changes from
                      22 October
                    </span>
                    <ChevronForward />
                  </Stack>
                </AccouncementText>
              </AnnouncementBanner>
            </Stack>
          </div>
          <ServiceUpdateSummary />
          <SearchWidget useSearchButton={true} />
        </HeroContainer>
      </Section>
      <Section>
        <TwoColumnContainer>
          <div className="main-text">
            <SectionHeading>
              Better for the planet, better for you
            </SectionHeading>
            <p>
              Travel with Ember and you'll be on the UK's first all-electric,
              intercity bus service. Zero exhaust emissions means it's good for
              the environment and you'll also enjoy a smoother, more peaceful
              journey – no engine rumble or smelly fumes here.
            </p>
          </div>
          <ExpandedImage>
            <GatsbyImage
              alt="Ember bus with cyclists passing"
              image={data.emberBus.image.gatsbyImageData}
            />
          </ExpandedImage>
        </TwoColumnContainer>
      </Section>
      <Section>
        <OneColumnContainer>
          <div className="main-text">
            <SectionHeading>Designed around passengers</SectionHeading>
            <p>
              We haven't stopped at making our buses electric. We've worked on
              every aspect of the experience to make travelling with Ember more
              comfortable and convenient.
            </p>
          </div>
          <FeaturesContainer>
            <Feature>
              <SeatExtraLegroom />
              <FeatureDescription>
                <h3>Comfortable seats</h3>
                <p>Lean back in our comfortable seats with extra legroom</p>
              </FeatureDescription>
            </Feature>
            <Feature>
              <Wifi />
              <FeatureDescription>
                <h3>USB and Wi-Fi</h3>
                <p>
                  Charge up with USB points at every seat and enjoy free 5G{" "}
                  <span>Wi-Fi</span>
                </p>
              </FeatureDescription>
            </Feature>
            <Feature>
              <SportEquipment />
              <FeatureDescription>
                <h3>Bikes and Wheelchairs</h3>
                <p>
                  Book a wheelchair or bike space for free, even minutes ahead
                </p>
              </FeatureDescription>
            </Feature>
            <Feature>
              <Passengers />
              <FeatureDescription>
                <h3>Friendly Drivers</h3>
                <p>
                  Whether with bags or directions, our drivers are happy to help
                </p>
              </FeatureDescription>
            </Feature>
            <Feature>
              <Radar />
              <FeatureDescription>
                <h3>Live Tracking</h3>
                <p>
                  Track your bus to the second, so you're not left waiting
                  around
                </p>
              </FeatureDescription>
            </Feature>
            <Feature>
              <Ticket />
              <FeatureDescription>
                <h3>Flexible Tickets</h3>
                <p>Changed your mind? Change or cancel for free at any point</p>
              </FeatureDescription>
            </Feature>
          </FeaturesContainer>
          <CTALink to="faq">Learn more in our FAQs</CTALink>
        </OneColumnContainer>
      </Section>
      <Section>
        <RouteMapSectionContainer>
          <div className="main-text">
            <SectionHeading>Track your bus in real-time</SectionHeading>
            <p>
              We have routes between Dundee, Edinburgh and Glasgow, with stops
              at multiple towns and villages in between. We also offer a
              seamless connection to Edinburgh Airport.
            </p>
            <p>
              You can track your bus with our live map, so you always know how
              long you have to wait.
            </p>
            <CTALink to="/map/">View the live map</CTALink>
          </div>
          <InView triggerOnce={true} rootMargin="30%">
            {({ ref, inView }) => (
              <div ref={ref}>{inView && <RouteMapSmall />}</div>
            )}
          </InView>
        </RouteMapSectionContainer>
      </Section>
    </Layout>
  )
}

export default Homepage

export const query = graphql`
  {
    emberBus: file(
      sourceInstanceName: { eq: "content" }
      relativePath: { eq: "images/ember-bus-by-beach.jpg" }
    ) {
      id
      name
      image: childImageSharp {
        gatsbyImageData(width: 712, layout: CONSTRAINED)
      }
    }
  }
`
